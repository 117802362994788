
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    url: { type: String, required: true },
    image: {
      type: Object,
      required: false,
      default: () => ({ _smUrl: 'images/fallback/345x206.png', _smWidth: 908, _smHeight: 545, _mdUrl: 'images/fallback/463x280.png', _mdWidth: 947, _mdHeight: 573 }),
    },
    imgAlt: { type: String, required: false, default: undefined },
    title: { type: String, required: true },
    when: { type: Date, required: false, default: undefined },
  },
  setup(props, { emit }) {
    return {
      onClick: (event) => emit('click', event),
    }
  },
})
