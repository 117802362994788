
import { defineComponent, ref, useRouter, useStore } from '@nuxtjs/composition-api'
import { toCESTDateString } from '~/assets/util'
import { explodeUrl } from '@/assets/link'

export default defineComponent({
  props: {
    recommendationUrl: { type: String, required: false, default: undefined },
    genres: { type: Array, required: false, default: () => [] },
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const concertSearchInput = ref('')
    const commitInputSearch = () => {
      const path = explodeUrl(store.getters['global/eventOverview']?.url).path
      router.push({ path, query: { keyword: concertSearchInput.value || undefined } })
    }
    const commitDatePickerSearch = (date) => {
      const path = explodeUrl(store.getters['global/eventOverview']?.url).path
      router.push({ path, query: { date: toCESTDateString(date) } })
    }

    return {
      commitInputSearch,
      commitDatePickerSearch,
      concertSearchInput,
    }
  },
})
