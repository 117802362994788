
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: false, default: undefined },
    buttons: { type: Array, required: false, default: () => [] },
    sponsors: { type: Array, required: false, default: () => [] },
  },
})
