
import { defineComponent, ref } from '@nuxtjs/composition-api'
import { setupDynamicContentItems } from '~/assets/composition/setup-dynamic-content-items'

export default defineComponent({
  props: {
    page: { type: Object, required: true },
  },
  setup({ page }) {
    const { groupedItems: groupedOverviewDynamicContent } = setupDynamicContentItems(page.overviewDynamicContent)
    const videoIsMuted = ref(true)
    return { groupedOverviewDynamicContent, videoIsMuted }
  },
})
