
import { computed, defineComponent, ref, useContext, useStore } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'
import { toDay } from '~/assets/date'
import { setupTranslationPrefixer } from '~/assets/util'
import { setupGtmDateFilterStart, setupGtmDateFilter } from '~/assets/composition/setup-gtm'

export default defineComponent({
  props: {
    backgroundColor: { type: String, required: false, default: '' },
    classList: { type: String, required: false, default: '' },
    enableIconLeft: { type: Boolean, required: false, default: false },
    iconColor: { type: String, required: false, default: '' },
    iconSize: { type: Number, required: false, default: 3 },
    isFullWidth: { type: Boolean, required: false, default: false },
    justified: { type: Boolean, required: false, default: false },
    shadowed: { type: Boolean, required: false, default: false },
    showButton: { type: Boolean, required: false, default: true },
    size: { type: String, required: false, default: 'medium' },
    textColor: { type: String, required: false, default: '' },
  },
  emits: ['setDate'],
  setup(props, { emit }) {
    const { app } = useContext()
    const store = useStore()
    const registerDateFilterStart = setupGtmDateFilterStart()
    const registerDateFilter = setupGtmDateFilter()
    const locale = app.i18n.locale
    const daysWithEvents = ref([])
    const today = toDay('today')
    const calendarAttributes = computed(() => [
      {
        key: 'today',
        highlight: {
          style: {
            backgroundColor: '#D9D9D6',
          },
          contentStyle: {
            color: 'white',
          },
        },
        dates: new Date(),
      },
      {
        highlight: false,
        dot: {
          style: {
            backgroundColor: '#71CC98',
            width: '7px',
            height: '7px',
            'margin-top': '3px',
          },
        },
        dates: daysWithEvents.value,
      },
    ])
    const setDate = (date) => {
      store.commit('event-overview/setDate', date)
      emit('setDate', date)
      registerDateFilter(date.toString().substring(0, 10))
    }
    const fromPage = (event) => {
      daysWithEvents.value = []
      store.dispatch('event-overview/fetchMonth', event).then((events) => {
        daysWithEvents.value = events
      })
    }
    const registerGtmDateFilterStart = () => {
      registerDateFilterStart()
    }
    return {
      ...setupTranslationPrefixer('Datepicker'),
      calendarAttributes,
      fromPage,
      locale,
      setDate,
      today,
      registerGtmDateFilterStart,
      registerDateFilter,
    }
  },
  computed: {
    ...mapGetters({
      date: 'event-overview/date',
      dateIsSpecified: 'event-overview/dateIsSpecified',
    }),
  },
})
