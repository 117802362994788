import { render, staticRenderFns } from "./HomeHeader.vue?vue&type=template&id=8fb91af0&scoped=true"
import script from "./HomeHeader.vue?vue&type=script&lang=js"
export * from "./HomeHeader.vue?vue&type=script&lang=js"
import style0 from "./HomeHeader.vue?vue&type=style&index=0&id=8fb91af0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fb91af0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomBrandConcertgebouwLogo: require('/home/userapp/components/Atom/Brand/BrandConcertgebouwLogo.vue').default,MoleculeButton: require('/home/userapp/components/Molecule/Button/Button.vue').default,MoleculePicture: require('/home/userapp/components/Molecule/Picture.vue').default,AtomLink: require('/home/userapp/components/Atom/Link.vue').default})
