
import { defineComponent } from '@nuxtjs/composition-api'
import { setupGtmPromoClick, setupGtmPromoView } from '~/assets/composition/setup-gtm'

export default defineComponent({
  props: {
    bannersSmall: { type: Array, required: true },
    bannersLarge: { type: Array, required: true },
  },
  setup({ bannersSmall, bannersLarge }) {
    const banners = bannersSmall.length === 2 ? bannersSmall : bannersLarge
    const getPosition = (el) => (el?.getBoundingClientRect()?.top || 0) + (window.pageYOffset || 0)

    const promoView = setupGtmPromoView()
    const onViewport = ({ el, banner }) =>
      promoView([
        {
          name: banner.title,
          creative: banner.subtitle,
          position: getPosition(el),
        },
      ])

    const promoClick = setupGtmPromoClick()
    const onClick = ({ el, banner }) => {
      promoClick([
        {
          name: banner.title,
          creative: banner.subtitle,
          position: getPosition(el),
        },
      ])
    }

    return {
      banners,
      onClick,
      onViewport,
    }
  },
})
