
import get from 'lodash/get'
import { defineComponent } from '@nuxtjs/composition-api'
import breakpointsList from '~/config/carousel'

export default defineComponent({
  props: {
    discoveries: { type: Array, required: true },
    discoveryCollections: { type: Array, required: true },
  },
  setup() {
    return { breakpointsList, get }
  },
})
